import { BButton } from '@/components/bootstrap'
import Link from '@/components/Link'
import WayupTable from '@/components/WayupTable'
import CourseCell from '@/components/WayupTable/cellTemplates/Course'
import UserCell from '@/components/WayupTable/cellTemplates/User'
import { CellData, Column } from '@/components/WayupTable/types'
import { isDarkColor } from '@/helpers/color'
import declination from '@/helpers/declination'
import axios from '@/libs/axios'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal'
import { Component, Ref, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { showError } from '@/helpers/notifications'
import { CourseType, getCourses, ICourse, LearnTime } from '@/api/courses'
import Switcher from '@/components/controls/Switcher'
import { loadFromLS, saveToLS } from '@/helpers/localStorage'

const ONLY_ACTIVE_KEY = 'course-page/only-active-courses'

@Component
export default class CoursesPage extends tsx.Component<{}> {
  @Ref() readonly deleteConfirmationModal!: DeleteConfirmationModal

  pending = false
  columns: Column<ICourse>[] = [
    {
      field: 'id',
      centered: true,
      sortable: true,
    },
    {
      field: 'title',
      title: 'Название',
      style: { minWidth: '200px' },
      sortable: true,
    },
    {
      field: 'teacher',
      title: 'Автор',
      sortable: { path: 'teacher.name' },
    },
    {
      field: 'type',
      title: 'Тип',
      formatter: (value: CourseType) =>
        value === 'free' ? 'Бесплатный' : 'Платный',
      centered: true,
      sortable: true,
    },
    {
      field: 'price',
      title: 'Цена',
      centered: true,
      formatter: value => (value ? `$${value}` : '-'),
      sortable: true,
    },
    {
      field: 'learnTime',
      title: 'Длительность',
      centered: true,
      formatter: ({ value, type }: LearnTime) => {
        let formattedType = ''
        switch (type) {
          case 'day':
            formattedType = declination(value, ['день', 'дня', 'дней'])
            break
          case 'week':
            formattedType = declination(value, ['неделя', 'недели', 'недель'])
            break
          case 'month':
            formattedType = declination(value, ['месяц', 'месяца', 'месяцев'])
            break
        }

        return `${value} ${formattedType}`
      },
      sortable: {
        sortFn(value1: LearnTime, value2: LearnTime) {
          return value1.type === value2.type
            ? value1.value - value2.value
            : value1.type === 'month'
            ? 1
            : -1
        },
      },
    },
    {
      field: 'tag',
      title: 'Тег',
      centered: true,
      sortable: { path: 'tag.label' },
    },
    {
      field: 'isActive',
      title: 'Статус',
      centered: true,
      sortable: true,
    },
    {
      customField: 'buttons',
      title: 'Действия',
    },
  ]

  courses: ICourse[] = []
  onlyActive = false

  get filteredCourses() {
    if (this.onlyActive) {
      return this.courses.filter(c => c.isActive)
    }
    return this.courses
  }

  @Watch('onlyActive')
  onOnlyActiveChanged() {
    saveToLS(ONLY_ACTIVE_KEY, this.onlyActive)
  }

  async mounted() {
    this.onlyActive = loadFromLS<boolean>(ONLY_ACTIVE_KEY) ?? false

    this.pending = true
    try {
      this.courses = await getCourses()
    } catch (error) {
      console.error(error)
      showError('При получении курсов произошла ошибка')
    }
    this.pending = false
  }

  async deleteCourse(course: ICourse) {
    const result = await this.deleteConfirmationModal.show(course.title)

    if (result) {
      try {
        this.pending = true
        await axios.delete(`v1/courses/${course.id}`)
        this.courses = this.courses.filter(c => c.id !== course.id)
      } catch (error) {
        console.error(error)
        showError('При удалении курса произошла ошибка')
      }
      this.pending = false
    }
  }

  renderCell({ row, column }: CellData<ICourse>) {
    if (column.customField === 'buttons') {
      return (
        <div>
          <BButton
            to={`/courses/edit/${row.id}`}
            variant="outline-primary"
            class="mr-50 p-50"
          >
            <feather-icon icon="EditIcon" size="16" />
          </BButton>
          <BButton
            onClick={() => this.deleteCourse(row)}
            variant="outline-danger"
            class="p-50"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </BButton>
        </div>
      )
    }

    switch (column.field) {
      case 'id':
        return <Link to={`/courses/${row.id}`}>{row.id}</Link>
      case 'teacher':
        return (
          <Link to={`/user/${row.teacher.id}`}>
            <UserCell avatar={row.teacher.avatar} firstRow={row.teacher.name} />
          </Link>
        )
      case 'title':
        return (
          <Link to={`/courses/${row.id}`}>
            <CourseCell
              title={row.title}
              image={row.image.url}
              color={row.image.color}
            />
          </Link>
        )
      case 'tag':
        return (
          <b-badge
            class={[isDarkColor(row.tag.color) ? 'text-white' : 'text-dark']}
            style={{ background: row.tag.color }}
          >
            {row.tag.label}
          </b-badge>
        )
      case 'isActive':
        return (
          <b-badge
            pill
            variant={`light-${row.isActive ? 'success' : 'danger'}`}
          >
            {row.isActive ? 'Открыт' : 'Закрыт'}
          </b-badge>
        )
    }
  }

  protected render() {
    return (
      <div>
        <div class="d-flex align-items-center mb-1">
          <BButton variant="primary" to="/courses/add" class=" mr-1">
            Создать курс
          </BButton>
          <Switcher
            value={this.onlyActive}
            onInput={value => (this.onlyActive = value)}
            class="ml-auto"
          >
            Только активные курсы
          </Switcher>
        </div>
        <WayupTable
          ref="table"
          rows={this.filteredCourses}
          totalRows={this.filteredCourses.length}
          columns={this.columns}
          local
          pending={this.pending}
          paginationEnabled
          perPage={20}
          scopedSlots={{ default: cellData => this.renderCell(cellData) }}
        />
        <DeleteConfirmationModal ref="deleteConfirmationModal" />
      </div>
    )
  }
}
